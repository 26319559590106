$base03:    '002b36';
$base02:    '073642';
$base01:    '586e75';
$base00:    '657b83';
$base0:     '839496';
$base1:     '93a1a1';
$base2:     'eee8d5';
$base3:     'fdf6e3';
$yellow:    'b58900';
$orange:    'cb4b16';
$red:       'dc322f';
$magenta:   'd33682';
$violet:    '6c71c4';
$blue:      '268bd2';
$cyan:      '2aa198';
$green:     '859900';

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-cyrillic-ext.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-cyrillic.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-greek-ext.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-greek.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/fira-code-regular-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-cyrillic-ext.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-cyrillic.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-greek-ext.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-greek-ext.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/fira-code-bold-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin rebase($rebase03, $rebase02, $rebase01, $rebase00, $rebase0, $rebase1, $rebase2, $rebase3) {
  background-color: unquote("##{$rebase03}");
  color: unquote("##{$rebase0}");

  * {
    color: unquote("##{$rebase0}");
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: unquote("##{$orange}");
    border-color: unquote("##{$rebase0}");
  }

  code {
    &.language-plaintext {
      background-color: unquote("##{$rebase02}");

      &::before,
      &::after {
        color: unquote("##{$red}");
      }
    }
  }

  a,
  a:active,
  a:visited {
    color: unquote("##{$cyan}");
  }

  &.light {
    .theme-selector {
      [type="range"] {
        &::-webkit-slider-thumb {
          background-image: url('/assets/images/mode-light-#{$base00}.svg');
        }

        &::-moz-range-thumb {
          background-image: url('/assets/images/mode-light-#{$base00}.svg');
        }
      }
    }
  }

  &.auto {
    .theme-selector {
      [type="range"] {
        &::-webkit-slider-thumb {
          @media (prefers-color-scheme: light) {
            background-image: url('/assets/images/mode-auto-#{$base00}.svg');
          }

          @media (prefers-color-scheme: dark) {
            background-image: url('/assets/images/mode-auto-#{$base0}.svg');
          }
        }

        &::-moz-range-thumb {
          @media (prefers-color-scheme: light) {
            background-image: url('/assets/images/mode-auto-#{$base00}.svg');
          }

          @media (prefers-color-scheme: dark) {
            background-image: url('/assets/images/mode-auto-#{$base0}.svg');
          }
        }
      }
    }
  }

  &.dark {
    .theme-selector {
      [type="range"] {
        &::-webkit-slider-thumb {
          background-image: url('/assets/images/mode-dark-#{$base0}.svg');
        }

        &::-moz-range-thumb {
          background-image: url('/assets/images/mode-dark-#{$base0}.svg');
        }
      }
    }
  }

  body {
    header,
    footer {
      background-color: unquote("##{$rebase02}");
      color: unquote("##{$rebase00}");
    }

    header {
      h1 {
        color: unquote("##{$green}");
      }
    }

    .theme-selector {
      background: unquote("##{$rebase03}");

      &::before {
        background-image: url('/assets/images/mode-light-#{$rebase00}.svg');
      }

      &::after {
        background-image: url('/assets/images/mode-dark-#{$rebase00}.svg');
      }

      [type="range"] {
        background-image: url('/assets/images/mode-auto-#{$rebase00}.svg');

        &::-webkit-slider-thumb {
          background-color: unquote("##{$rebase03}");
        }

        &::-moz-range-thumb {
          background-color: unquote("##{$rebase03}");
        }
      }
    }
  }
}

html {
  font-family: 'Fira Code', monospace;
  margin: 0;
  padding: 0;

  * {
    color-profile: sRGB;
    rendering-intent: auto;
  }

  &.auto {
    @media (prefers-color-scheme: light) {
      @include rebase($base3, $base2, $base1, $base0, $base00, $base01, $base02, $base03)
    }

    @media (prefers-color-scheme: dark) {
      @include rebase($base03, $base02, $base01, $base00, $base0, $base1, $base2, $base3)
    }
  }

  &.light {
    @include rebase($base3, $base2, $base1, $base0, $base00, $base01, $base02, $base03)
  }

  &.dark {
    @include rebase($base03, $base02, $base01, $base00, $base0, $base1, $base2, $base3)
  }

  body {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: 1.5;
    margin: 0;
    min-height: 100vh;
    padding: 0;

    header {
      padding: 3rem 0 0;

      h1 {
        font-size: 3rem;
      }

      img {
        border: .3rem solid unquote("##{$yellow}");
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        max-width: 256px;
        width: 50%;

        @media screen and (min-width: 600px) {
          border: .4rem solid unquote("##{$yellow}");
        }

        @media screen and (min-width: 992px) {
          border: .5rem solid unquote("##{$yellow}");
        }

        @media screen and (min-width: 1200px) {
          border: .6rem solid unquote("##{$yellow}");
        }
      }
    }

    main {
      flex: 1;
      margin: 0 auto;
      max-width: 80ch;
      width: 90%;
      text-align: justify;

      @media screen and (min-width: 600px) {
        font-size: 1.3rem;
        width: 85%;
      }

      @media screen and (min-width: 992px) {
        font-size: 1.4rem;
        width: 80%;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
        width: 75%;
      }

      .line-height-2 {
        line-height: 2;
      }

      .center-left-outer {
        text-align: center;

        .center-left-inner {
          display: inline-block;
          text-align: left;
          width: auto;
          max-width: 90%;
        }
      }

      .center {
        text-align: center;
      }

      code {
        &.language-plaintext {
          border-radius: .25rem;
          font-weight: normal;
          margin: -.1rem;
          padding: .1rem;

          &::before,
          &::after {
            content: '`';
          }
        }
      }
    }

    footer {
      margin: 3rem 0 0;
      font-size: 1rem;
      padding: 1rem;
      text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }

    h2::before,
    h3::before,
    h4::before,
    h5::before,
    h6::before {
      font-weight: normal;
    }

    h2::before {
      content: '# ';
    }

    h3::before {
      content: '## ';
    }

    h4::before {
      content: '### ';
    }

    h5::before {
      content: '#### ';
    }

    h6::before {
      content: '##### ';
    }

    a,
    a:active,
    a:visited {
      font-weight: bold;
      text-decoration: none;

      &.contact {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;

        &::before,
        &::after {
          color: unquote("##{$base0}");
          font-weight: normal;
        }

        &::before {
          background-repeat: no-repeat;
          background-size: 1.6rem;
          content: '[  ](';
          font-weight: normal;
          margin-top: -.4rem;
          padding-top: .4rem;

          @media screen and (min-width: 600px) {
            background-size: 1.733rem;
            margin-top: -.433rem;
            padding-top: .433rem;
          }

          @media screen and (min-width: 992px) {
            background-size: 1.867rem;
            margin-top: -.467rem;
            padding-top: .467rem;
          }

          @media screen and (min-width: 1200px) {
            background-size: 2rem;
            margin-top: -.5rem;
            padding-top: .5rem;
          }
        }

        &::after {
          content: ')';
        }

        &[href*="mailto:"]::before {
          background-image: url('/assets/images/email.svg');
          background-position: .666rem .4rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .433rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .467rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .5rem;
          }
        }

        &[href*="matrix.to"]::before {
          background-image: url('/assets/images/matrix.svg');
          background-position: .666rem .666rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .722rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .777rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .833rem;
          }
        }

        &[href*="gitlab.com"]::before {
          background-image: url('/assets/images/gitlab.svg');
          background-position: .666rem .266rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .289rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .311rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .333rem;
          }
        }

        &[href*="github.com"]::before {
          background-image: url('/assets/images/github.svg');
          background-position: .666rem .266rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .289rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .311rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .333rem;
          }
        }

        &[href*="linkedin.com"]::before {
          background-image: url('/assets/images/linkedin.svg');
          background-position: .666rem .266rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .289rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .311rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .333rem;
          }
        }

        &[href*="chaos.social"]::before {
          background-image: url('/assets/images/mastodon.svg');
          background-position: .666rem .266rem;

          @media screen and (min-width: 600px) {
            background-position: .722rem .289rem;
          }

          @media screen and (min-width: 922px) {
            background-position: .777rem .311rem;
          }

          @media screen and (min-width: 1200px) {
            background-position: .833rem .333rem;
          }
        }
      }
    }

    .theme-selector {
      position: absolute;
      height: 2.5rem;
      width: 7.5rem;
      right: 0;

      :hover {
        cursor: pointer;
      }

      &::before,
      &::after {
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        content: '';
        display: inline-block;
        height: 2.5rem;
        position: absolute;
        width: 2.5rem;
        z-index: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        left: 5rem;
      }

      [type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        height: 2.5rem;
        margin: 0;
        outline: none;
        position: absolute;
        width: 7.5rem;
        z-index: 1;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 1.875rem;
          cursor: pointer;
          height: 1.875rem;
          width: 2.75rem;
          border-radius: 0;
          border: 0;
        }

        &::-moz-range-thumb {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 1.875rem;
          cursor: pointer;
          height: 1.875rem;
          width: 2.75rem;
          border-radius: 0;
          border: 0;
        }
      }
    }
  }
}
